<template>
  <div class="answer-module">
    <!--  -->
    <div class="answer-central">
    <div class="body-content-img">
        <img src="../../assets/back-left.png" alt="" width="30px" height="30px" @click="$router.back()"/>
      </div>
      <div class="answer-central-section" v-if="questionDetailList">
        <img class="flex-width-head" :src="questionDetailList.avatar" alt="" />
        <div class="answer-central-section-content">
          <div class="content-name">{{ questionDetailList.creatorName }}</div>
          <div class="content-time">{{ questionDetailList.createTime }}</div>
          <p class="content-p html_value" v-html="questionDetailList.content"></p>
        </div>
      </div>
      <div class="reply-title">
        <div>
          {{
            questionReplyList.length > 0 ? questionReplyList.length : "0"
          }}回复
        </div>
        <div class="reply-title-comment" @click="comment">
          <div class="reply-title-comment-text">评论</div>
          <img src="../../assets/comment.png" class="flex-width-huifu" alt="" />
        </div>
      </div>
      <div class="answer-central-detail" v-if="questionReplyList.length > 0">
        <div
          class="answer-central-section"
          v-for="(item, index) in questionReplyList"
          :key="index"
        >
          <img class="flex-width-head" :src="item.avatar" alt="" />
          <div class="answer-central-section-content">
            <div class="content-name">{{ item.creatorName }}</div>
            <div class="content-time">{{ item.createTime }}</div>
            <p class="content-p html_value" v-html="item.content"></p>
          </div>
        </div>
      </div>
      <div v-else>
        <el-empty description="暂无回复"></el-empty>
      </div>
      <el-pagination
        v-if="total / pageSize > 1"
        style="margin: 20px 0"
        @current-change="currentChange"
        background
        layout="prev, pager, next"
        :total="total"
        :hide-on-single-page="true"
      ></el-pagination>
    </div>
    <el-dialog :visible.sync="addAnswerDialogVisible" width="850" center>
      <div class="dialog">
        <quill-editor
          v-model="content"
          ref="myQuillEditor"
          :options="editorOption"
          @blur="onEditorBlur($event)"
          @focus="onEditorFocus($event)"
          @change="onEditorChange($event)"
          class="quill"
        >
        </quill-editor>
      </div>
      <div class="button">
        <el-button size="mini" type="primary" @click="add" class="submit"
          >提交</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  getQuestionInfo,
  getQuestionReplyList,
  addQuestionReply,
} from "@/api/course";
import { quillEditor, Quill } from "vue-quill-editor";
import { container, ImageExtend, QuillWatch } from "quill-image-extend-module";
Quill.register("modules/ImageExtend", ImageExtend);
var that;
export default {
  data() {
    return {
      questionDetailList: [], //答疑列表
      total: 0, //总页数
      pageNum: 1, //当前页
      pageSize: 10, //每页行数,
      id: 0,
      questionReplyList: [],
      addAnswerDialogVisible: false,
      content: ``,
      editorOption: {
        modules: {
          ImageExtend: {
            // 如果不作设置，即{}  则依然开启复制粘贴功能且以base64插入
            name: "file", // 图片参数名
            size: 3, // 可选参数 图片大小，单位为M，1M = 1024kb
            action:
              "https://www.zhijiao168.com/cloudClassroom/app/api/app/system/upload/file", // 服务器地址, 如果action为空，则采用base64插入图片
            // response 为一个函数用来获取服务器返回的具体图片地址
            // 例如服务器返回{code: 200; data:{ url: 'baidu.com'}}
            // 则 return res.data.url
            response: (res) => {
              return res.data;
            },
            headers: (xhr) => {
              xhr.setRequestHeader(
                "authentication",
                localStorage.getItem("web-token")
              );
            }, // 可选参数 设置请求头部
            sizeError: () => {}, // 图片超过大小的回调
            start: () => {}, // 可选参数 自定义开始上传触发事件
            end: () => {}, // 可选参数 自定义上传结束触发的事件，无论成功或者失败
            error: () => {}, // 可选参数 上传失败触发的事件
            success: () => {}, // 可选参数  上传成功触发的事件
            change: (xhr, formData) => {}, // 可选参数 每次选择图片触发，也可用来设置头部，但比headers多了一个参数，可设置formData
          },
          toolbar: {
            // 如果不上传图片到服务器，此处不必配置
            container: [["image"]], // container, // container为工具栏，此次引入了全部工具栏，也可自行配置
            handlers: {
              image: function () {
                // 劫持原来的图片点击按钮事件
                QuillWatch.emit(this.quill.id);
              },
            },
          },
        },
        placeholder: "请输入评论的内容",
      },
    };
  },
  created() {
    that = this;
    that.id = that.$route.query.id;
    this.getQuestionDetail();
    this.getQuestionReply();
  },
  methods: {
    add() {
      if (this.content == "") {
        this.$message({ message: "请输入内容", type: "error" });
        return;
      }
      addQuestionReply({
        questionId: that.id,
        content: this.content,
      }).then((res) => {
        if (res.code == 200) {
          this.$message({
            message: "回复成功，请等待审核通过！",
            type: "success",
          });
          this.addAnswerDialogVisible = false;
        }
      });
    },
    onEditorReady(editor) {
      // 富文本编辑框准备编辑器
    },
    onEditorBlur() {}, // 富文本编辑框失去焦点事件
    onEditorFocus() {}, // 富文本编辑框获得焦点事件
    onEditorChange() {}, // 富文本编辑框内容改变事件
    comment() {
      this.addAnswerDialogVisible = true;
    },
    //答疑详情
    getQuestionDetail() {
      getQuestionInfo({ id: that.id }).then((res) => {
        if (res.code == 200) {
          that.questionDetailList = res.data;
        } else {
          alert(res.msg);
        }
      });
    },

    //答疑回复
    getQuestionReply() {
      getQuestionReplyList({ questionId: that.id }).then((res) => {
        if (res.code == 200) {
          that.questionReplyList = res.data.list;
          this.total = res.data.total;
        } else {
          alert(res.msg);
        }
      });
    },

    currentChange(event) {
      this.pageNum = event;
      this.getQuestion();
    },
  },
};
</script>
<style lang="scss" scoped>
.body-content-img {
  margin: 20px;
  width: 800px;
}
.body-content-img img {
  cursor: pointer;
}
.html_value {
  text-align: left;
  width: 95%;
  font-size: 16px;
  /deep/ img {
    width: 95%;
    margin: 5px 0;
  }
  /deep/ table {
    width: 95%;
    font-size: 12px;
  }
}
.answer-module {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  // background-color: #F4F5F8;
}
.answer-central {
  max-width: 1200px;
  width: 45%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  border: 1px solid #eeeeee;
  border-radius: 5px;
  margin-top: 50px;
}
.answer-central-detail {
  width: 88%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.answer-central-section {
  width: 90%;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #f3f3f3;
  margin-top: 30px;
  .flex-width-head {
    width: 32px;
    height: 32px;
    border-radius: 50%;
  }
  .flex-width-content {
    width: 201px;
    height: 142px;
    opacity: 1;
    border-radius: 5px;
  }
}
.answer-central-section-content {
  width: 90%;
  margin-left: 10px;
  margin-bottom: 30px;
  .content-name {
    font-size: 14px;
    font-weight: bold;
    color: #000000;
  }
  .content-time {
    font-size: 8px;
    font-weight: 400;
    color: #9e9e9e;
  }
  .content-p {
    font-size: 14px;
    font-weight: 400;
    color: #000000;
  }
}
.flex-width-huifu {
  width: 24px;
  height: 24px;
  margin-left: 5px;
}
.reply-title {
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
  .reply-title-comment {
    font-size: 18px;
    font-weight: bold;
    display: -webkit-inline-box;
    cursor: pointer;
    .reply-title-comment-text {
      color: #4394ff;
    }
  }
}
.quill {
  width: 100%;
  height: 400px;
}
.button {
  width: 100%;
  text-align: right;
  .submit {
    margin-top: 80px;
  }
}
</style>